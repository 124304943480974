import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import logoStyle from "../../assets/jss/material-kit-react/logoStyle.jsx";
import { Link } from "gatsby";
import Logo from "../../assets/img/logo.png";
import AniLink from "gatsby-plugin-transition-link/AniLink";



function Logoimage() {
    return (
      <div>
              <AniLink swipe top="exit" duration={2} to="/"><img src={Logo} alt="logo" className="logosize" />
</AniLink>
      </div>
    )
  }

  export default withStyles(logoStyle)(Logoimage);